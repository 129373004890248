import React from 'react'
import { Link } from 'gatsby'
import CtaRow from 'components/cta-row'
import { documentIcon, documentTemplateIcon } from 'images/hulp/tools'
import Helmet from 'react-helmet'
import Layout from '../../layouts'


const ToolsPage = ({location}) => (
  <Layout location={location}>
  <main className="tools animated fadeInPage">

    <Helmet>
      <title>Handige tools salarisadministratie & HR | Employes</title>
      <meta name="description" content="Gebruik onze handige tools en handleidingen over salarisadministratie en aanverwante zaken." />
      <meta itemprop="name" content="Handige tools salarisadministratie & HR| Employes" />
      <meta itemprop="description" content="Gebruik onze handige tools en handleidingen over salarisadministratie en aanverwante zaken."/>
      <meta itemprop="image" content="/static/meta-img.png" />

      {/* Windows tags */}
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="static/favicon/mstile-144x144.png" />

      {/* OpenGraph tags */}
      <meta property="og:url" content="" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Handige tools salarisadministratie & HR| Employes" />
      <meta property="og:description" content="Gebruik onze handige tools en handleidingen over salarisadministratie en aanverwante zaken." />
      <meta property="og:image" content="https://employes.nl/static/meta-tools.jpg" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Handige tools salarisadministratie & HR | Employes" />
      <meta name="twitter:description" content="Gebruik onze handige tools en handleidingen over salarisadministratie en aanverwante zaken." />
      <meta name="twitter:image" content="https://employes.nl/static/meta-tools.jpg" />
    </Helmet>

    <header className="padding-xl">
      <div className="container-md">
        <diV className="grid yg center text-center">
          <div className="col-12">
            <h2 className="eyebrow">Hulp</h2>
            <h1>Tools</h1>
            <p className="streamer center">Gebruik onze handige tools als hulpmiddel tijdens het doen of inrichten van je salarisadministratie.</p>
          </div>
        </diV>
      </div>
    </header>


    <section className="tools-cards padding-xxl">
      <div className="container-md">
        <div className="grid yg">

            <div className="col-6 margin-l-bottom content-box tools-cards-card">
              <div className="tools-cards-single card">
                <p className="eyebrow">Tool</p>
                <h3 className="margin-xxs-bottom">Aanvragen loonbelastingnummer</h3>
                <p className="margin-s-bottom">Als je voor het eerst personeel in dienst neemt, heb je een loonheffingenummer nodig. Hiermee vraag je het formulier aan.</p>
                <Link to="/tools/aanvragen-loonbelastingnummer/" className="btn secondary">Bekijk tool <span className="arrow right"></span></Link>
              </div>
            </div>

            <div className="col-6 margin-l-bottom content-box tools-cards-card">
              <div className="tools-cards-single card">
                <p className="eyebrow">Tool</p>
                <h3 className="margin-s-bottom">Bruto-netto berekening</h3>
                <p className="margin-s-bottom">Bepaal het netto-loon en de loonheffing via onze gratis omrekentool. Je kunt deze tool gebruiken voor zowel werknemers als DGA's.</p>
                <Link to="/tools/bruto-netto-berekening/" className="btn secondary">Bekijk tool <span className="arrow right"></span></Link>
              </div>
            </div>

            <div className="col-6 margin-l-bottom content-box tools-cards-card">
              <div className="tools-cards-single card">
                <p className="eyebrow">Tool</p>
                <h3 className="margin-s-bottom">Bijtelling auto van de zaak</h3>
                <p className="margin-s-bottom">Bepaal de bijtelling van je auto van de zaak op basis van het kenteken.</p>
                <Link to="/tools/bijtelling-auto-van-de-zaak/" className="btn secondary">Bekijk tool <span className="arrow right"></span></Link>
              </div>
            </div>

            <div className="col-6 margin-l-bottom content-box tools-cards-card">
              <div className="tools-cards-single card">
                <p className="eyebrow">Tool</p>
                <h3 className="margin-xxs-bottom">Checklist werknemer aannemen</h3>
                <p className="margin-s-bottom">Krijg een handig overzicht van wat je moet doen om je nieuwe werknemer toe te kunnen voegen aan je administratie.</p>
                <Link to="/tools/checklist-werknemer-aannemen/" className="btn secondary">Bekijk tool <span className="arrow right"></span></Link>
              </div>
            </div>

            <div className="col-6 margin-l-bottom content-box tools-cards-card">
              <div className="tools-cards-single card">
                <p className="eyebrow">Tool</p>
                <h3 className="margin-xxs-bottom">Payroll vs. salarisadministratie</h3>
                <p className="margin-s-bottom">Bereken hoeveel geld je kunt besparen door je werknemers zelf in dienst te nemen.</p>
                <a href="https://welkom.employes.nl/payroll-alternatief" target="_blank" className="btn secondary">Bekijk tool <span className="arrow right"></span></a>
              </div>
            </div>



        </div>
      </div>
{/*
    </section>

    <CtaRow small light text="Kan je niet vinden waar je naar zoekt? " btnText="Stel een vraag" linkTo="#"/>

    <section className="padding-xxl-top padding-l-bottom">

      <div className="container-md">
        <div className="grid yg margin-l-bottom">
          <div className="col-7">
            <p className="eyebrow">Handig</p>
            <h3 className="margin-xs-bottom">Download onze handige, gratis gidsen</h3>
            <p className="margin-s-bottom">We helpen je graag een handje als je werknemers in dienst hebt of die voor het eerst gaat aannemen.</p>
          </div>
        </div>

        <div className="grid yg">

          <div className="col-6 margin-l-bottom content-box">
            <div className="tools-cards-single card">
              <div className="grid">
                <div className="col-2 no-pad file-icon-image">
                  <img src={documentTemplateIcon}/>
                </div>

                <div className="col-10">
                  <p className="eyebrow">Uitleg</p>
                  <h3 className="margin-xxs-bottom">Wet arbeidsmarkt in balans</h3>
                  <p className="margin-s-bottom">Een uitleg over wat de WAB (Wet arbeidsmarkt in balans) inhoudt. We geven je praktische tips over hoe je kunt voldoen aan de regels.</p>
                  <Link to="#" className="btn secondary">Download <span className="arrow right"></span></Link>
                </div>
              </div>
            </div>
          </div>


            <div className="col-6 margin-l-bottom content-box">
              <div className="tools-cards-single card">
                <div className="grid">
                  <div className="col-2 no-pad file-icon-image">
                    <img src={documentTemplateIcon}/>
                  </div>

                  <div className="col-10">
                    <p className="eyebrow">Uitleg</p>
                    <h3 className="margin-xxs-bottom">Beginnen met salarisadministratie</h3>
                    <p className="margin-s-bottom">Dolor maecenas nascetur fringilla per cum vivamus phasellus aliquet, dictum nullam vulputate.</p>
                    <Link to="#" className="btn secondary">Download <span className="arrow right"></span></Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 margin-l-bottom content-box">
              <div className="tools-cards-single card">
                <div className="grid">
                  <div className="col-2 no-pad file-icon-image">
                    <img src={documentTemplateIcon}/>
                  </div>

                  <div className="col-10">
                    <p className="eyebrow">Uitleg</p>
                    <h3 className="margin-xxs-bottom">Overstappen van salarisadministratie</h3>
                    <p className="margin-s-bottom">Dolor maecenas nascetur fringilla per cum vivamus phasellus aliquet, dictum nullam vulputate.</p>
                    <Link to="#" className="btn secondary">Download <span className="arrow right"></span></Link>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
*/}
    </section>


  </main>
  </Layout>
)

export default ToolsPage
